import { Helmet, HelmetProvider } from 'react-helmet-async';

const SchemaOrg: React.FC = (): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: 'Daniël Staal',
    jobTitle: 'Creative front-end developer',
    url: 'https://www.danielstaal.com',
    sameAs: ['https://www.danielstaal.nl'],
    image: 'https://www.danielstaal.com/photo.webp',
    knowsLanguage: [
      { '@type': 'Language', name: 'Dutch' },
      { '@type': 'Language', name: 'English' },
    ],
    alumniOf: [
      {
        '@type': 'EducationalOrganization',
        name: 'The Hague University of Applied Sciences (THUAS)',
        url: 'https://www.thuas.com',
        sameAs: [
          'https://www.dehaagsehogeschool.com',
          'https://www.thehagueuniversity.com',
          'https://www.dehaagsehogeschool.nl',
        ],
        hasCredential: {
          '@type': 'EducationalOccupationalCredential',
          name: 'Communication and Multimedia Design',
          description:
            'Communication and Multimedia Design (CMD) is a study program focused on designing and developing interactive digital media. Students learn to combine conceptual and technical skills to create effective and user-friendly digital solutions. The program emphasizes user experience (UX) design, visual design, interaction design, and the integration of media and technology to achieve communication goals.',
        },
      },
      {
        '@type': 'EducationalOrganization',
        name: 'Grafisch Lyceum Rotterdam',
        url: 'https://www.glr.nl',
        hasCredential: {
          '@type': 'EducationalOccupationalCredential',
          name: 'Digital Media Designer',
          description:
            'Digital Media Designer (DMD) is a study program focused on various digital media. Students learn skills in graphic design, interactive media, and digital production, emphasizing creativity and technical proficiency for shaping visual and interactive experiences.',
        },
      },
    ],
    hasOccupation: {
      '@type': 'Occupation',
      name: 'Creative front-end developer',
      description:
        'Daniël Staal is a creative front-end developer who provides technical solutions for creative challenges. Creating user-friendly, accessible, and robust digital solutions that seamlessly blend creativity with functionality.',
      skills: [
        'Creative development',
        'Front-end development',
        'Creative front-end development',
        'Web development',
        'User-friendly',
        'Accessible',
        'Robust',
        'Digital experiences',
        'Digital solutions',
        'Engagement',
        'Connections',
        'Brands',
        'Audiences',
        'Users',
        'Prototyping',
        'Usability',
        'Animations',
        'Design systems',
        'Performance',
        'Test automation',
        'Figma',
        'Sketch',
        'Zeplin',
        'InVision',
        'Framer',
        'HTML',
        'CSS',
        'Sass',
        'Tailwind CSS',
        'JavaScript',
        'Webpack',
        'Babel',
        'Gulp',
        'ESLint',
        'Stylelint',
        'Prettier',
        'Storybook',
        'Pattern Lab',
        'TypeScript',
        'React',
        'Angular',
        'Jasmine',
        'Selenium',
      ],
    },
  };

  return (
    <HelmetProvider>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </HelmetProvider>
  );
};

export default SchemaOrg;
