import { NextUIProvider } from '@nextui-org/react';
import ReactDOM from 'react-dom/client';

import App from './App.tsx';
import SchemaOrg from './components/SchemaOrg.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <NextUIProvider>
    <SchemaOrg />
    <div className="light text-foreground bg-background">
      <App />
    </div>
  </NextUIProvider>
);
